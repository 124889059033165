import 'react-native-gesture-handler';
import React, { useContext } from "react";
import { View } from 'react-native';
import { Text, Button, Image, makeStyles } from '@rneui/themed';
import { DrawerContentScrollView, DrawerItemList, DrawerItem, } from '@react-navigation/drawer';
import Icon from 'react-native-vector-icons/Ionicons';
import { Platform } from 'react-native';
import { Context, modeData } from "./GlobalContext.js";
import AsyncStorage from '@react-native-async-storage/async-storage';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
    width: "100%",
    backgroundColor: theme.colors.background,
  },
  drawerLogo: {
    width: 60,
    height: 60
  },
  drawerTitle: {
    fontSize: 26,
    fontWeight: "bold"
  },
  drawerTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    marginLeft: 6,
    marginBottom: 10,
    marginTop: 5,
  },
  drawerSubtitle: {
    fontSize: 18,
    marginLeft: 15,
    marginBottom: 15,
    marginTop: 15,
    fontWeight: "bold"
  },
}));

export function DrawerContent(props) {
  const styles = useStyles()
  const navigation = props.navigation
  const [user, setUser, restaurant, setRestaurant, mode, setMode] = useContext(Context);

  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.drawerTitleBox}>
        <Image style={styles.drawerLogo} source={modeData[mode].logo}/>
        <Text style={styles.drawerTitle}>Cartesan</Text>
      </View>
      <Text style={styles.drawerSubtitle}>{modeData[mode].collectionNoun}</Text>
      <DrawerItem
        label={"View " + modeData[mode].collectionNoun.toLowerCase()}
        onPress={() => {
          navigation.navigate("Home")
        }}
        icon={({focused, color, size}) => 
          <Icon color={color} size={size} name={focused ? 'book' : 'book-outline'} />
        }
      />
      <DrawerItem
        label="Preferences"
        onPress={() => {
          navigation.navigate("Preferences")
        }}
        icon={({focused, color, size}) => 
          <Icon color={color} size={size} name={focused ? 'brush' : 'brush-outline'} />
        }
      />
      <DrawerItem
        label="QR code"
        onPress={() => {
          navigation.navigate("QR")
        }}
        icon={({focused, color, size}) => 
          <Icon color={color} size={size} name={focused ? 'qr-code' : 'qr-code-outline'} />
        }
      />
      <Text style={styles.drawerSubtitle}>Account</Text>
      <DrawerItem
        label="Settings"
        onPress={() => {
          navigation.navigate("Settings")
        }}
        icon={({focused, color, size}) => 
          <Icon color={color} size={size} name={focused ? 'settings' : 'settings-outline'} />
        }
      />
      <DrawerItem
        label="Change mode"
        onPress={() => {
          try {
            AsyncStorage.removeItem('@Cartesan_mode').then(() => {
              navigation.navigate("Mode")
            })
          } catch(e) {
            console.log(e)
          }
        }}
        icon={({focused, color, size}) => 
          <Icon color={color} size={size} name={focused ? 'cube' : 'cube-outline'} />
        }
      />
      <DrawerItem
        label="Log out"
        onPress={() => {
          try {
            AsyncStorage.removeItem('@Cartesan_user').then(() => {
              navigation.navigate("Login")
              setUser(null)
            })
          } catch(e) {
            console.log(e)
          }
        }}
        icon={({focused, color, size}) => 
          <Icon color={color} size={size} name={focused ? 'log-out' : 'log-out-outline'} />
        }
      />
      <Text style={styles.drawerSubtitle}>Support</Text>
      <DrawerItem
        label="Help"
        onPress={() => {
          navigation.navigate("Help")
        }}
        icon={({focused, color, size}) => 
          <Icon color={color} size={size} name={focused ? 'help-circle' : 'help-circle-outline'} />
        }
      />
      <DrawerItem
        label="About"
        onPress={() => {
          navigation.navigate("About")
        }}
        icon={({focused, color, size}) => 
          <Icon color={color} size={size} name={focused ? 'information-circle' : 'information-circle-outline'} />
        }
      />
      <DrawerItem
        label="Tutorial"
        onPress={() => {
          navigation.navigate("Tutorial")
        }}
        icon={({focused, color, size}) => 
          <Icon color={color} size={size} name={focused ? 'document-text' : 'document-text-outline'} />
        }
      />
    </DrawerContentScrollView>
  );
}