import 'react-native-gesture-handler';
import React, { useCallback, useState } from "react";
import { View, Linking, Dimensions } from 'react-native';
import { createTheme, useThemeMode, ThemeProvider, Text, Button, Image, makeStyles } from '@rneui/themed';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem, } from '@react-navigation/drawer';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { LoginScreen } from './components/LoginScreen'
import { HomeScreen } from './components/HomeScreen'
import { ItemScreen } from './components/ItemScreen'
import { EditorScreen } from './components/EditorScreen'
import { PreferencesScreen } from './components/PreferencesScreen'
import { QRCodeScreen } from './components/QRCodeScreen'
import { CameraScreen } from './components/CameraScreen'
import { HelpScreen } from './components/HelpScreen'
import { AboutScreen } from './components/AboutScreen'
import { SettingsScreen } from './components/SettingsScreen'
import { TutorialScreen } from './components/TutorialScreen'
import { Context } from "./components/GlobalContext.js";
import { RootSiblingParent } from 'react-native-root-siblings';
import { DrawerContent } from './components/DrawerContent';
import { ModeScreen } from './components/ModeScreen';

const Drawer = createDrawerNavigator();

const theme = createTheme({
  mode: 'light',
  components: {
    Button: {
      radius: 'sm',
    },
  },
});

export default function App() {
  const [user, setUser] = useState(null);
  const [mode, setMode] = useState(null);
  const [restaurant, setRestaurant] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      <SafeAreaProvider>
        <RootSiblingParent>
          <Context.Provider value={[user, setUser, restaurant, setRestaurant, mode, setMode]}>
            <NavigationContainer>
              <Drawer.Navigator drawerContent={(props) => <DrawerContent {...props} />}
                screenOptions={{
                  swipeEdgeWidth: 0,
                  drawerType: "front",
                  drawerStyle: {
                    width: Dimensions.get('window').width * (0.65),
                    maxWidth: 260
                  }}
                } backBehavior="history">
                <Drawer.Screen name="Mode" component={ModeScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="Login" component={LoginScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="Home" component={HomeScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="Item" component={ItemScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="Editor" component={EditorScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="Preferences" component={PreferencesScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="QR" component={QRCodeScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="Settings" component={SettingsScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="Camera" component={CameraScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="Help" component={HelpScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="About" component={AboutScreen} options={{headerShown: false}}/>
                <Drawer.Screen name="Tutorial" component={TutorialScreen} options={{headerShown: false}}/>
              </Drawer.Navigator>
            </NavigationContainer>
          </Context.Provider>
        </RootSiblingParent>
      </SafeAreaProvider>
    </ThemeProvider>
  );

}