import React, { useCallback, useContext, useState } from "react";
import { View, Linking, TouchableOpacity, Dimensions } from 'react-native';
import { createTheme, useThemeMode, ThemeProvider, Text, Button, Image, makeStyles } from '@rneui/themed';
import { Header as HeaderRNE } from '@rneui/themed';
import { Context, modeData } from "./GlobalContext.js";
import Icon from 'react-native-vector-icons/Ionicons';

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        flexGrow: 1,
        padding: 20,
        paddingTop: 0,
        width: "100%",
        backgroundColor: theme.colors.background,
    },
    header: {
        marginTop: 0,
        paddingTop: 5,
    },
    headerTransparent: {
        paddingTop: 5,
        opacity: 0.5
    },
    heading: {
        backgroundColor: theme.colors.background,
    },
    headerMenuIcon: {
        justifyContent: "center",
        alignItems: "center",
        marginTop: 15,
    },
    headerLogo: {
        marginTop: 0,
        width: 60,
        height: 60,
    }
}));

export function Header(props) {
    const styles = useStyles();
    const navigation = props.nav
    const itemIndex = props.itemIndex
    const section = props.section
    const restaurantId = props.restaurantId
    const leftComponentType = props.leftComponentType
    const rightComponentType = props.rightComponentType
    const rightComponentCallback = props.rightComponentCallback
    const transparency = props.transparency
    const padding = props.padding

    const [user, setUser, restaurant, setRestaurant, mode, setMode] = useContext(Context);

    const components = {
        "menu": (<TouchableOpacity style={styles.headerMenuIcon}
                    onPress={() => {navigation.toggleDrawer();}}
                >
                    <Icon name="menu-outline" size={35} color="black" />
                </TouchableOpacity>),
        "add": (<TouchableOpacity style={styles.headerMenuIcon}
                    onPress={() => {navigation.navigate("Camera")}}
                >
                    <Icon name="add-outline" size={35} color="black" />
                </TouchableOpacity>),
        "back": (<TouchableOpacity style={styles.headerMenuIcon}
                    onPress={async () => {
                        navigation.goBack()
                        await new Promise(r => setTimeout(r, 50))

                    }}
                >
                    <Icon name="arrow-back-outline" size={35} color="black" />
                </TouchableOpacity>),
        "editor": (<TouchableOpacity style={styles.headerMenuIcon}
            onPress={async () => {
                navigation.navigate("Editor", {
                    itemIndex: itemIndex,
                    section: section,
                    restaurantId: restaurantId
                })
                await new Promise(r => setTimeout(r, 50))
            }}
        >
            <Icon name="options-outline" size={35} color="black" />
        </TouchableOpacity>),
        "help": (<TouchableOpacity style={styles.headerMenuIcon}
            onPress={rightComponentCallback}
        >
            <Icon name="document-text-outline" size={32} color="black" />
        </TouchableOpacity>),
        "none": (<View></View>)
    }

  
    return (
        <HeaderRNE
            containerStyle={transparency ? styles.headerTransparent : styles.header}
            backgroundColor="white"
            leftComponent={components[leftComponentType]}
            rightComponent={components[rightComponentType]}
            centerComponent={
                <Image style={styles.headerLogo} source={modeData[mode].logo}/>
            }
        />
    )
  }