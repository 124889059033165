import React, { useCallback, useState, useRef, useContext } from "react";
import { View, TouchableOpacity, Dimensions, Linking, ScrollView, Platform } from 'react-native';
import { Text, Button, makeStyles } from '@rneui/themed';
import { Header } from './Header';
import Icon from 'react-native-vector-icons/Ionicons';
import LottieViewNative from 'lottie-react-native';
import { useFocusEffect } from '@react-navigation/native';

var LottieViewWeb = null
if (Platform.OS == 'web') {
    LottieViewWeb = require('react-native-web-lottie').default
}

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
    width: "100%",
    backgroundColor: theme.colors.background,
  },
  introBox: {
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 10,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    paddingBottom: 75
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20
  },
  description: {
    fontSize: 16,
    marginBottom: 15,
    textAlign: "center"
  },
  animation: {
    width: 300,
    height: 300,
    overflow: "hidden"
  },
  animationResized: {
    marginTop: -40,
    marginLeft: -4,
    width: 287,
    height: 567,
  },
  animationResizedHome: {
    marginTop: -30,
    marginLeft: -10,
    width: 375,
    height: 375,
  },
  animationResizedQRCode: {
    marginTop: 0,
    width: 325,
    height: 325,
  },
  nextButtonBox: {
    marginVertical: 20
  },
  nextButton: {
    width: 200
  },
  iconBox: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: (Dimensions.get('window').width - 55),
  }
}));

export function TutorialScreen({route, navigation}) {
    const styles = useStyles();
    const [screen, setScreen] = useState(0)
    let LottieView = null

    if (Platform.OS == 'web') {
        LottieView = LottieViewWeb
    } else {
        LottieView = LottieViewNative
    }

    useFocusEffect(
        useCallback(() => {
            setScreen(0)
        }, [])
    );

    const nextScreen = () => {
        if (screen >= screens.length - 1) {
            navigation.navigate("Home")
        } else {
            setScreen(screen + 1)
        }
    }

    const previousScreen = () => {
        if (screen >= 1) {
            setScreen(screen - 1)
        }
    }

    const Intro = (    
        <View style={styles.introBox}>
            <LottieView
            autoPlay
            style={styles.animation}
            source={require('../assets/plate.json')}
            />
            <Text style={styles.title}>Welcome to Cartesan!</Text>
            <Text style={styles.description}>Cartesan lets you make 3D menus.</Text>
            <Text style={styles.description}>Let's learn how to get started.</Text>
            <Button
                containerStyle={styles.nextButtonBox}
                buttonStyle={styles.nextButton}
                color="grey"
                onPress={nextScreen}
            >
                Next
            </Button>
        </View>
    )

    const About = (    
        <View style={styles.introBox}>
            <TouchableOpacity
                style={styles.iconBox}
                onPress={previousScreen}
            >
                <Icon name="chevron-back-outline" size={35} color="black" />
            </TouchableOpacity>
            <LottieView
            autoPlay
            style={styles.animation}
            source={require('../assets/menu-paper.json')}
            />
            <Text style={styles.title}>What is Cartesan?</Text>
            <Text style={styles.description}>Cartesan creates 3D models.</Text>
            <Text style={styles.description}>Using a 30 second video, we make your items into a beautiful 3D model!</Text>
            <Text style={styles.description}>Your items can be updated any time and be viewed online through a link or QR code.</Text>
            <Button
                containerStyle={styles.nextButtonBox}
                buttonStyle={styles.nextButton}
                color="grey"
                onPress={nextScreen}
            >
                Next
            </Button>
        </View>
    )

    const Add = (    
        <View style={styles.introBox}>
            <TouchableOpacity
                style={styles.iconBox}
                onPress={previousScreen}
            >
                <Icon name="chevron-back-outline" size={35} color="black" />
            </TouchableOpacity>
            <View style={styles.animation}>
                <LottieView
                autoPlay
                style={styles.animationResized}
                source={require('../assets/scan.json')}
                speed={1}
                />
            </View>
            <Text style={styles.title}>Adding an item</Text>
            <Text style={styles.description}>To add an item, press the '+' icon.</Text>
            <Text style={styles.description}>Then, record a video covering every angle of your item.</Text>
            <Text style={styles.description}>Avoid objects that are shiny or without texture. Our model doesn't yet handle those well!</Text>
            <Button
                containerStyle={styles.nextButtonBox}
                buttonStyle={styles.nextButton}
                color="grey"
                onPress={nextScreen}
            >
                Next
            </Button>
        </View>
    )

    const Edit = (    
        <View style={styles.introBox}>
            <TouchableOpacity
                style={styles.iconBox}
                onPress={previousScreen}
            >
                <Icon name="chevron-back-outline" size={35} color="black" />
            </TouchableOpacity>
            <View style={styles.animation}>
                <LottieView
                autoPlay
                style={styles.animationResizedHome}
                source={require('../assets/home.json')}
                />
            </View>
            <Text style={styles.title}>Set details</Text>
            <Text style={styles.description}>Press an item to set its name, description, price, and section.</Text>
            <Text style={styles.description}>Changes will appear instantly.</Text>
            <Button
                containerStyle={styles.nextButtonBox}
                buttonStyle={styles.nextButton}
                color="grey"
                onPress={nextScreen}
            >
                Next
            </Button>
        </View>
    )

    const Menu = (    
        <View style={styles.introBox}>
            <TouchableOpacity
                style={styles.iconBox}
                onPress={previousScreen}
            >
                <Icon name="chevron-back-outline" size={35} color="black" />
            </TouchableOpacity>
            <View style={styles.animation}>
                <LottieView
                autoPlay
                style={styles.animationResizedQRCode}
                source={require('../assets/qr-code.json')}
                />
            </View>
            <Text style={styles.title}>View items</Text>
            <Text style={styles.description}>Press the QR code tab to share your items.</Text>
            <Text style={styles.description}>Display your QR code or menu link and let customers see your beautiful creations!</Text>
            <Button
                containerStyle={styles.nextButtonBox}
                buttonStyle={styles.nextButton}
                color="grey"
                onPress={nextScreen}
            >
                Get started!
            </Button>
        </View>
    )

    const screens = [Intro, About, Add, Edit, Menu]

    return (    
      <View style={styles.box}>
        <Header nav={navigation} leftComponentType="menu" rightComponentType="none"/>
        {screens[screen]}
      </View>
    )
  }