import React, { useCallback, useState, useEffect, useRef } from "react";
import { View, Linking, TouchableOpacity, Dimensions, KeyboardAvoidingView, ScrollView, TouchableWithoutFeedback, Platform } from 'react-native';
import { Text, Button, Image, Input, CheckBox, makeStyles } from '@rneui/themed';
import { Header } from './Header';
import Icon from 'react-native-vector-icons/Ionicons';
import DropDownPicker from 'react-native-dropdown-picker';
import CurrencyInput from 'react-native-currency-input';
import { getMenu, getRestaurant, updateMenuItem, deleteMenuItem } from '../js/cartesanApi'
import { useFocusEffect } from '@react-navigation/native';
import { WebView } from 'react-native-webview';

const maxWidth = 400

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
    width: "100%",
    backgroundColor: theme.colors.background,
  },
  imageBox: {
    width: (Dimensions.get('window').width - 40),
    height: Dimensions.get('window').width > 750 ? Dimensions.get('window').height - 100 : (Dimensions.get('window').height - 125),
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 0,
  },
  itemModel: {
    width: (Dimensions.get('window').width - 40),
    height: Dimensions.get('window').width > 750 ? Dimensions.get('window').height - 100 : (Dimensions.get('window').width - 40),
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 0
  },
}));

function editModelUrl(itemIndex, section, restaurantId, height) {
    return "https://crtsn.com/view/editor?restaurantId=" + restaurantId + "&section=" +  section + "&itemIndex=" + itemIndex + "&height=" + height
}

export function EditorScreen({route, navigation}) {
    const styles = useStyles();
    let { itemIndex, section, restaurantId } = route.params;
    const webViewRef = useRef()

    useFocusEffect(
      useCallback(() => {
            async function refreshData() {
                itemIndex, section, restaurantId = route.params;
            }
            refreshData()
      }, [route])
    );

    console.log(editModelUrl(itemIndex, section, restaurantId, (Dimensions.get('window').width - 80)))

    const INJECTED_JAVASCRIPT = `const meta = document.createElement('meta'); meta.setAttribute('content', 'initial-scale=1, maximum-scale=1, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `

    return (    
        <View style={styles.box}>
            <Header nav={navigation} leftComponentType="back" rightComponentType="none"/>
            <View style={styles.imageBox}>
                { Platform.select({
                        web: <iframe style={styles.itemModel} src={editModelUrl(itemIndex, section, restaurantId, Dimensions.get('window').width > 750 ? maxWidth : (Dimensions.get('window').width - 80))}/>,
                        ios: <WebView ref={(ref) => webViewRef.current = ref} scrollEnabled={true} style={styles.itemModel} 
                                injectedJavaScript={INJECTED_JAVASCRIPT} onMessage={() => {}} scalesPageToFit={true} bounces={false}                          
                                source={{uri: editModelUrl(itemIndex, section, restaurantId, (Dimensions.get('window').width - 80))}}/>
                    })
                }
            </View>
        </View>
              
    )
  }