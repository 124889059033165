import React, { useState } from "react";
import { View, TouchableOpacity, Dimensions, ScrollView } from 'react-native';
import { Text, makeStyles } from '@rneui/themed';
import { Header } from './Header';
import Icon from 'react-native-vector-icons/Ionicons';
import { Context } from "./GlobalContext.js";

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
    width: "100%",
    backgroundColor: theme.colors.background,
  },
  introBox: {
    marginTop: 10,
    marginBottom: 20
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20
  },
  description: {
    fontSize: 16,
    marginBottom: 15
  },
  questionBox: {
    margin: 10,
    marginBottom: 100
  },
  questionTextBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center"
  },
  questionText: {
    fontSize: 18,
    flexGrow: 1,
    marginBottom: 15
  },
  answerText: {
    fontSize: 16,
    marginBottom: 15,
    color: "#555555"
  },
}));

function QuestionDropdown(props) {
    const styles = useStyles();
    const [open, setOpen] = useState(false)

    const toggle = () => {
        setOpen(!open)
    }
    
    return (
        <TouchableOpacity onPress={toggle}>
            <View style={styles.questionTextBox}>
                <Text style={styles.questionText}>{props.question}</Text>
                {open ? 
                    <Icon name="chevron-up-outline" size={25} color="black" />:
                    <Icon name="chevron-down-outline" size={25} color="black" />
                }
            </View>
            {open && <Text style={styles.answerText}>{props.answer}</Text>}
        </TouchableOpacity>
    )
}

export function HelpScreen({route, navigation}) {
    const styles = useStyles();

    const questions = [
        {question: "What is Cartesan?", answer: "Cartesan is a company that lets restaurants make 3D menus. We are making a platform for restaurants to present foods in AR. \n\nWe are hoping to revolutionize the everyday restaurant menu, providing better experiences for customers ordering their favorite dish and restaurants presenting their best creations."},
        {question: "How do I use Cartesan?", answer: "Using our mobile app, take a few pictures from different angles of your dish. Cartesan will automatically generate a 3D model for your dish and display it on a QR code for customers to scan."},
        {question: "How do I edit my menu?", answer: "Using our mobile app, click on menu items on the \"View menu\" screen. There, restaurant owners can edit item names, descriptions, pricing, sections, and more."},
        {question: "Where can I view my menu?", answer: "Menus can be previewed in the mobile app, or seen in the browser by scanning a QR code generated in the app."},
        {question: "Do I need special cameras?", answer: "No! All you need is your phone's camera."},
        {question: "How do I take good photos?", answer: "To generate good models, try the following tips.\n\n" + 
            "Take more photos. 20-30 photos is suitable for good lighting and material conditions. Up to 50 may be helpful for suboptimal conditions.\n\n" +
            "Avoid glossy materials. Glossy (shiny, reflective, etc.) materials make it hard to determine object shape. Try using a matte textured plate instead.\n\n" +
            "Avoid textureless materials. Surfaces that are completely even and without texture make it hard to determine object shape. Try using plates with patterns and texture instead.\n\n" +
            "Have good lighting. Take photos with multiple sources of diffused lighting (light coming from all directions).\n\n"
        },
        {question: "How do I hide items?", answer: "To hide a menu item, navigate to \"View menu\" and press an item. Mark the checkbox labeled \"Hidden\". This will prevent the item from showing up on your online menu."},
        {question: "How do I mark items as sold out?", answer: "To mark a menu item as sold out, navigate to \"View menu\" and press an item. Mark the checkbox labeled \"Sold Out\". This will label the item as \"Sold Out\" on your online menu, but the item will remain visible."},
        {question: "What new features are coming?", answer: "Cartesan is working on many new features, including API access for 3D models and a web interface for menu creation and management. \n\nIf you have any feature requests, please reach out to us at support@cartesan.app!"},
    ]

    return (    
      <View style={styles.box}>
        <Header nav={navigation} leftComponentType="menu" rightComponentType="none"/>
        <ScrollView style={styles.questionBox}>
            <View style={styles.introBox}>
                <Text style={styles.title}>Help</Text>
                <Text style={styles.description}>Thanks for using Cartesan! If you have questions, please send an email to support@cartesan.app!</Text>
                <Text style={styles.description}>Below are some answers to frequently asked questions.</Text>
            </View>
           
            {questions.map((entry, index) => {
                return <QuestionDropdown key={index} question={questions[index].question} answer={questions[index].answer}/>
            })}
        </ScrollView>
      </View>
    )
  }