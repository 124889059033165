import React, { useCallback, useState, useRef, useContext } from "react";
import { View, TouchableOpacity, Dimensions, Linking, ScrollView } from 'react-native';
import { Text, makeStyles } from '@rneui/themed';
import { Header } from './Header';
import Icon from 'react-native-vector-icons/Ionicons';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
    width: "100%",
    backgroundColor: theme.colors.background,
  },
  introBox: {
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 10
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20
  },
  description: {
    fontSize: 16,
    marginBottom: 15
  },
  iconBox: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    marginBottom: 10,
    marginHorizontal: 10
  },
  iconCaption: {
    fontSize: 16,
    marginLeft: 10
  },
}));

export function AboutScreen({route, navigation}) {
    const styles = useStyles();

    return (    
      <View style={styles.box}>
        <Header nav={navigation} leftComponentType="menu" rightComponentType="none"/>
        <ScrollView style={styles.questionBox}>
            <View style={styles.introBox}>
                <Text style={styles.title}>About</Text>
                <Text style={styles.description}>Founded in 2022, Cartesan hopes to improve the buying experience. Customers often enter a store or restaurants or order online and receive dishes and products that were not what they imagined.</Text>
                <Text style={styles.description}>Cartesan lets business owners create 3D models, allowing businesses to show off their products as they were intended to be seen.</Text>
                <Text style={styles.description}>If you have any inquiries or feedback, please reach out to us.</Text>
            </View>
            <View style={styles.iconBox}>
                <Icon name="mail-outline" size={25} color="black" />
                <Text style={styles.iconCaption}>support@cartesan.app</Text>
            </View>
            <View style={styles.iconBox}>
                <Icon name="globe-outline" size={25} color="black" />
                <Text style={styles.iconCaption}>www.cartesan.app</Text>
            </View>
            <View style={styles.iconBox}>
                <Icon name="reader-outline" size={25} color="black" />
                <Text style={styles.iconCaption}>www.cartesan.app/privacy</Text>
            </View>
            <View style={styles.iconBox}>
                <Icon name="school-outline" size={25} color="black" />
                <Text style={styles.iconCaption}>www.cartesan.app/terms</Text>
            </View>
        </ScrollView>
      </View>
    )
  }