import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useContext } from "react";
import { Touchable, View, Linking, Platform } from 'react-native';
import { Text, Button, makeStyles, Image } from '@rneui/themed';
import "firebase/auth";
import { Context } from "./GlobalContext.js";
import { getMenu, addUser, getUser, updateUser } from '../js/cartesanApi'
import * as AppleAuthentication from 'expo-apple-authentication';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TouchableOpacity } from 'react-native-gesture-handler';

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
        padding: 20,
        backgroundColor: theme.colors.background,
    },
    logo: {
        width: 60,
        height: 60
    },
    titleText: {
        textAlign: 'center',
        fontSize: 22,
        fontWeight: "bold",
        margin: 15
    },
    descText: {
        textAlign: 'center',
        fontSize: 16,
        margin: 15
    },
    optionBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#F6F6F6',
        borderColor: "#EEEEEE",
        borderRadius: 8,
        borderWidth: 0,
        marginBottom: 5,
        width: 250,
        cursor: "pointer"
    },
    optionText: {
        fontSize: 18,
        fontWeight: 'bold',
        paddingLeft: 10,
    }
}));

export function ModeScreen({ navigation }) {
    const styles = useStyles();
    const [user, setUser, restaurant, setRestaurant, mode, setMode] = useContext(Context);

    useEffect(() => {
        if (Platform.OS == 'web') {
            var url = window.location.href
            var regex = /[?&]([^=#]+)=([^&#]*)/g,
                params = {},
                match;
            while (match = regex.exec(url)) {
                params[match[1]] = match[2];
            }
            if (params['mode']) {
                handleSelectOption(params['mode'])
            }
        }
    }, [])

    const getData = async () => {
        try {
            const value = await AsyncStorage.getItem('@Cartesan_mode')
            if (value != null) {
                const valueObj = value != null ? JSON.parse(value) : null
                setMode(valueObj)
            }
        } catch(e) {
            console.log(e)
        }
    }

    const setModeData = async (mode) => {
        setMode(mode)
        if (mode != null) {
            const jsonValue = JSON.stringify(mode)
            await AsyncStorage.setItem('@Cartesan_mode', jsonValue)
            navigation.navigate('Login');
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const handleSelectOption = (option) => {
        setModeData(option)
    }

    return (
        <View style={styles.box}>
            <Text style={styles.titleText}>Welcome to Cartesan</Text>
            <TouchableOpacity style={styles.optionBox} onPress={() => handleSelectOption("food")}>
                <Image style={styles.logo} source={require('../assets/logo.png')}/>
                <Text style={styles.optionText}>I'm a restaurant</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.optionBox} onPress={() => handleSelectOption("shopping")}>
                <Image style={styles.logo} source={require('../assets/logoshopping-500.png')}/>   
                <Text style={styles.optionText}>I'm a business</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.optionBox} onPress={() => handleSelectOption("general")}>
                <Image style={styles.logo} source={require('../assets/logobear-500.png')}/>   
                <Text style={styles.optionText}>I'm here for fun</Text>
            </TouchableOpacity>
            <Text style={styles.descText}>You can change this later!</Text>
        </View>
    )
}