// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAkLyqMeJHPMu9sy3gdRXt_rUrvehyv0xU",
  authDomain: "cartesan-d6f20.firebaseapp.com",
  databaseURL: "https://cartesan-d6f20-default-rtdb.firebaseio.com",
  projectId: "cartesan-d6f20",
  storageBucket: "cartesan-d6f20.appspot.com",
  messagingSenderId: "904552438870",
  appId: "1:904552438870:web:75bdc6c7aa08d3bf00aff7"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
export const auth = getAuth(app);