import { createContext } from "react";

export const Context = createContext({});
export const modeData = {
    food: {
        logo: require("../assets/logo.png"),
        logoText: "Make your 3D menu",
        collectionNoun: "Menu",
        showProductControls: true
    },
    shopping: {
        logo: require("../assets/logoshopping-500.png"),
        logoText: "Make your 3D store",
        collectionNoun: "Catalog",
        showProductControls: true
    },
    general: {
        logo: require("../assets/logobear-500.png"),
        logoText: "Make things into 3D",
        collectionNoun: "Collection",
        showProductControls: false
    },
    null: {
        logo: require("../assets/logobear-500.png"),
        logoText: "Make things into 3D",
        collectionNoun: "Collection",
        showProductControls: false
    }
}
