import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useContext } from "react";
import { Touchable, View, Linking, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Text, Button, makeStyles, Image } from '@rneui/themed';
import { URLButton } from './URLButton';
import "firebase/auth";
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { GoogleAuthProvider, signInWithCredential, onAuthStateChanged } from 'firebase/auth';
import { Context, modeData } from "./GlobalContext.js";
import { getMenu, addUser, getUser, updateUser } from '../js/cartesanApi'
import * as AppleAuthentication from 'expo-apple-authentication';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TouchableOpacity } from 'react-native-gesture-handler';

var StyledFirebaseAuth = null
if (Platform.OS == 'web') {
    StyledFirebaseAuth = require('./StyledFirebaseAuth').default
}

const firebase = require("../firebaseConfig")
const auth = firebase.auth
const webClientID = "904552438870-bbhajnl0ptg87c1hmltgri70jghgmh2q.apps.googleusercontent.com"
const iOSClientId = "904552438870-pjr3sjtdj2sl65q1o0kmenp6mlbpnqaq.apps.googleusercontent.com"

WebBrowser.maybeCompleteAuthSession();

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'center',
        padding: 20,
        backgroundColor: theme.colors.background,
    },
    logo: {
        width: 100,
        height: 100
    },
    titleText: {
        textAlign: 'center',
        fontSize: 34,
        fontWeight: "bold"
    },
    descText: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: "bold",
    },
    loginTitle: {
        fontSize: 16
    },
    loginButtonBox: {
        margin: 10,
        marginTop: 50,
        display: 'flex',
        alignItems: 'center',
    },
    loginButtonView: {
        margin: 0,
    },
    loginButton: {
        padding: 0,
        marginBottom: 5,
        width: 225,
        height: 45,
        fontSize: 18,
        overflow: "hidden"
    },
    googleLoginButtonView: {
        margin: 0,
        padding: 0,
    },
    googleLoginButton: {
        padding: 0,
        width: 225,
        height: 45,
        margin: 0,
        marginBottom: 5,
        borderColor: "grey",
        overflow: "hidden"
    },
    googleText: {
        color: "white",
        fontSize: 18,
        paddingRight: 16,
    },
    googleLogo: {
        width: 49,
        height: 49,
        marginRight: 8
    },
    loginTerms: {
        width: 225,
        fontSize: 14,
        textAlign: "center",
    },
    loginTermsLink: {
        marginTop: 0,
        textAlign: "center",
        fontSize: 14,
        color: "#8A8686"
    },
    loginTermsLinksBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
    }
}));

export function LoginScreen({ navigation }) {
    const styles = useStyles();
    const [user, setUser, restaurant, setRestaurant, mode, setMode] = useContext(Context);

    useEffect(() => {
        if (Platform.OS == 'web') {
            var url = window.location.href
            var regex = /[?&]([^=#]+)=([^&#]*)/g,
                params = {},
                match;
            while (match = regex.exec(url)) {
                params[match[1]] = match[2];
            }
            if (params['id']) {
                getUser(params['id'], ((response) => {
                    setUser(response)
                }))
            }
        }
    }, [])

    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /account after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/',
        signInOptions: [
          "google.com"
        ],
        privacyPolicyUrl: "https://cartesan.app/privacy"
    };  

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                addUser(user.uid, user.displayName, user.email, "google", null, () => {
                    getUser(user.uid, ((response) => {
                        setUser(response)
                    }))
                })
            }
        });
    }, [])
    

    const getData = async () => {
        try {
            const value = await AsyncStorage.getItem('@Cartesan_user')
            if (value != null) {
                const valueObj = value != null ? JSON.parse(value) : null
                setUser(valueObj)
            }
        } catch(e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const handleUserChange = async () => {
        try {
            if (user != null) {
                if (user.first_time) {
                    user.first_time = false
                    let userData = {
                        "first_time": false
                    }
                    updateUser(user.user_id, userData)
                    navigation.navigate('Tutorial');
                } else {
                    navigation.navigate('Home');
                }
                const jsonValue = JSON.stringify(user)
                await AsyncStorage.setItem('@Cartesan_user', jsonValue)
            }
        } catch(e) {
            console.log(e)
        }
    }

    useFocusEffect(() => {
        getData()
    })

    useEffect(() => {
        handleUserChange()
    }, [user])

    const [request, response, promptAsync] = Google.useAuthRequest(
        {      
            expoClientId: webClientID,
            webClientId: webClientID,
            iosClientId: iOSClientId
        },
    );

    useEffect(() => {
        if (response?.type === 'success') {
            const { authentication } = response;
            const credential = GoogleAuthProvider.credential(
                null,
                authentication.accessToken
            )
            const signIn = signInWithCredential(auth, credential)
            signIn.then((output) => {
                const user = output.user;
                addUser(user.uid, user.displayName, user.email, "google", null, () => {
                    getUser(user.uid, ((response) => {
                        setUser(response)
                    }))
                })
            })
        }
    }, [response]);

    const signIn = async () => {
        if (Platform.OS === "ios") {
            promptAsync()
        }
        if (Platform.OS === "web") {
            promptAsync()
        }
        console.log("Signed in with Google")
    }

    const signInApple = async () => {
        console.log("Signed in with Apple")
        try {
            const credential = await AppleAuthentication.signInAsync({
                requestedScopes: [
                    AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                    AppleAuthentication.AppleAuthenticationScope.EMAIL,
                ],
            });

            // Apple Auth only provides user data on first sign in
            // Subsequent sign ins return null for user data
            const userId = credential.user.replaceAll('.', '-')
            const userFullName = credential.fullName['givenName'] + ' ' + credential.fullName.familyName
            const userEmail = credential.email
            const authCode = credential.authorizationCode
            addUser(userId, userFullName, userEmail, "apple", authCode, () => {
                getUser(userId, ((response) => {
                    setUser(response)
                }))
            })
            // signed in
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <View style={styles.box}>
            <Text style={styles.titleText}>Cartesan</Text>
            <Image style={styles.logo} source={modeData[mode].logo}/>   
            <Text style={styles.descText}>{modeData[mode].logoText}</Text>
            <View style={styles.loginButtonBox}>
                {Platform.OS === "web" && 
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                }
                {Platform.OS === "ios" && 
                    <View>
                        <Button
                            onPress={signIn}
                            containerStyle={styles.googleLoginButtonView}
                            buttonStyle={styles.googleLoginButton}
                            color="#4285F4"
                        >
                            <Image style={styles.googleLogo} source={require('../assets/google-icon.png')}/>
                            <Text style={styles.googleText}>Sign In with Google</Text>
                        </Button>
                        <AppleAuthentication.AppleAuthenticationButton
                            buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                            buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                            style={styles.loginButton}
                            onPress={signInApple}
                        />
                        <URLButton
                            color="grey"
                            url="https://www.cartesan.app"
                            buttonStyle={styles.loginButton}
                            style={styles.loginButtonView}
                        >
                            About us
                        </URLButton>
                    </View>
                }
                <Text style={styles.loginTerms}>By signing in, you agree to the
                </Text>
                <View style={styles.loginTermsLinksBox}>
                    <TouchableOpacity onPress={() => Linking.openURL("https://cartesan.app/terms")}>
                        <Text style={styles.loginTermsLink}>Terms of Use</Text>
                    </TouchableOpacity>
                    <Text> and </Text>
                    <TouchableOpacity onPress={() => Linking.openURL("https://cartesan.app/privacy")}>
                        <Text style={styles.loginTermsLink}>Privacy Policy</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <StatusBar style="auto" />
        </View>
    )
}