import React, { useCallback, useState, useRef, useContext } from "react";
import { View, TouchableOpacity, Dimensions, Linking, ScrollView } from 'react-native';
import { Text, makeStyles } from '@rneui/themed';
import { Header } from './Header';
import Icon from 'react-native-vector-icons/Ionicons';
import QRCode from 'react-native-qrcode-svg';
import * as Sharing from 'expo-sharing';
import ViewShot from "react-native-view-shot";
import { Context, modeData } from "./GlobalContext.js";

const maxQRSize = 400

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
    width: "100%",
    backgroundColor: theme.colors.background,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold"
  },
  qrCodeView: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    marginVertical: 20,
  },
  qrCodeBox: {
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 5,
    borderRadius: 8,
    marginHorizontal: 30,
    width: Dimensions.get('window').width - 90,
    height: Dimensions.get('window').width - 90,
    maxWidth: maxQRSize,
    maxHeight: maxQRSize,
    padding: 10
  },
  qrCode: {
    width: (Dimensions.get('window').width - 40),
    maxWidth: maxQRSize,
    maxHeight: maxQRSize
  },
  button: {
    alignSelf: "center",
    justifyContent: "center",
    flexGrow: 1,
    display: "flex",
    flexDirection: "row"
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 30,
  },
  instructionsBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: 30
  },
  instructionsTitle: {
    fontSize: 24,
    marginBottom: 10,
  },
  instructionsText: {
    fontSize: 16,
    marginBottom: 5,
    textAlign: "left"
  },
  menuTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    marginTop: 15,
    marginBottom: 0,
  },
  menuTitle: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginRight: 5
  },
  menuDescriptionBox: {
    marginTop: 20
  },
  menuDescription: {
    fontSize: 16,
    textAlign: "center",
  },
}));

export function QRCodeScreen({route, navigation}) {
    const styles = useStyles();
    const [user, setUser, restaurant, setRestaurant, mode, setMode] = useContext(Context);
    const restaurantId = user.restaurant_ids[0]
    const menuUrl = "https://crtsn.com/menu/" + restaurantId
    const [qrUrl, setQrUrl] = useState(null)
    const restaurantName = restaurant.name

    const onCapture = useCallback(uri => {
        if (qrUrl == null) {
            setQrUrl(uri)
        }
    }, []);

    const openMenuLink = useCallback(async () => {
        // Checking if the link is supported for links with custom URL scheme.
        const supported = await Linking.canOpenURL(menuUrl);
    
        if (supported) {
          // Opening the link with some app, if the URL scheme is "http" the web link should be opened
          // by some browser in the mobile
          await Linking.openURL(menuUrl);
        } else {
          Alert.alert(`Don't know how to open this URL: ${menuUrl}`);
        }
    }, [qrUrl]);

    const shareQRCode = () => {
        Sharing.shareAsync("file://" + qrUrl)
    }

    if (!user.active_subscription) {
      const openSetupLink = async () => {
          // Checking if the link is supported for links with custom URL scheme.
          const supported = await Linking.canOpenURL(setupUrl);
      
          if (supported) {
            // Opening the link with some app, if the URL scheme is "http" the web link should be opened
            // by some browser in the mobile
            await Linking.openURL(setupUrl);
          } else {
            Alert.alert(`Don't know how to open this URL: ${setupUrl}`);
          }
      }

      return (
          <View style={styles.box}>
              <Header restaurantId={restaurantId} nav={navigation} leftComponentType="menu"/>
              <ScrollView>
                  <View style={styles.menuTitleBox}>
                      <Text style={styles.menuTitle}>Setup your account</Text>
                      <TouchableOpacity onPress={openSetupLink}>
                          <Icon name="open-outline" size={25} color="black"/>
                      </TouchableOpacity>
                  </View>
                  <View style={styles.menuDescriptionBox}>
                      <Text style={styles.menuDescription}>No subscription license found.</Text>
                      <Text style={styles.menuDescription}>Visit cartesan.app to register.</Text>
                  </View>
              </ScrollView>
          </View>
      )
    }

    return (    
      <View style={styles.box}>
        <Header nav={navigation} leftComponentType="menu" rightComponentType="none"/>
        <Text style={styles.title}>{restaurantName + " " + modeData[mode].collectionNoun}</Text> 
        <ViewShot style={styles.qrCodeView} onCapture={onCapture} captureMode="mount">
            <View style={styles.qrCodeBox}>
                <QRCode
                    value={menuUrl}
                    size={Math.min(Dimensions.get('window').width - 140, maxQRSize)}
                />
            </View>
        </ViewShot>
        <View style={styles.buttonBox}>
            <TouchableOpacity style={styles.button} onPress={shareQRCode}>
                <Icon name="share-outline" size={35} color="black" />
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={openMenuLink}>
                <Icon name="link-outline" size={35} color="black" />
            </TouchableOpacity>
        </View>
        <View style={styles.instructionsBox}>
          <Text style={styles.instructionsTitle}>Instructions</Text>
          <Text style={styles.instructionsText}>1. Save the QR code</Text>
          <Text style={styles.instructionsText}>2. Display the QR code</Text>
          <Text style={styles.instructionsText}>3. Scan to see items in browser</Text>
        </View>
      </View>
    )
  }