import React, { useCallback, useContext, useState, useEffect, useRef } from "react";
import { View, Linking, TouchableOpacity, Dimensions, KeyboardAvoidingView, ScrollView, TouchableWithoutFeedback, Platform } from 'react-native';
import { Text, Button, Image, Input, CheckBox, makeStyles } from '@rneui/themed';
import { Header } from './Header';
import Icon from 'react-native-vector-icons/Ionicons';
import DropDownPicker from 'react-native-dropdown-picker';
import CurrencyInput from 'react-native-currency-input';
import { Context, modeData } from "./GlobalContext.js";
import { getMenu, getRestaurant, updateMenuItem, deleteMenuItem } from '../js/cartesanApi'
import { useFocusEffect } from '@react-navigation/native';
import { WebView } from 'react-native-webview';

const maxWidth = 400

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
    width: "100%",
    backgroundColor: theme.colors.background,
  },
  imageBox: {
    width: (Dimensions.get('window').width - 40),
    height: Dimensions.get('window').width > 750 ? maxWidth : (Dimensions.get('window').width - 80),
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 0,
    marginBottom: 5,
  },
  itemModel: {
    width: (Dimensions.get('window').width - 40),
    height: Dimensions.get('window').width > 750 ? maxWidth: (Dimensions.get('window').width - 80),
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 0
  },
  itemTitle: {
    fontSize: 24,
    borderBottomWidth: 0,
    marginBottom: 0,
  },
  itemLabel: {
    fontSize: 14,
    marginBottom: 5,
    marginLeft: 10,
    marginTop: -3,
  },
  itemDescription: {
    fontSize: 16,
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 10,
    paddingTop: 13,
    paddingBottom: 13,
  },
  dropdown: {
    width: (Dimensions.get('window').width - 60),
  },
  dropdownContainer: {
    marginLeft: 10,
    marginBottom: 15,
    width: (Dimensions.get('window').width - 60),
  },
  dropdownText: {
    fontSize: 16,
  },
  dropdownItemsContainer: {
    height: 135,
  },
  itemPrice: {
    marginLeft: 10,
    marginBottom: 20,
    fontSize: 16,
    borderWidth: 1,
    borderRadius: 8,
    width: 85,
    padding: 5,
    paddingHorizontal: 10,
    paddingVertical: 13
  },
  saveButton: {
    backgroundColor: "#2196F3",
    fontSize: 16,
    width: (Dimensions.get('window').width - 80) / 2,
    marginRight: 20
  },
  deleteButton: {
    backgroundColor: "#9E9E9E",
    fontSize: 16,
    width: (Dimensions.get('window').width - 70) / 2
  },
  buttonContainer: {
    display: "flex",
    flexDirection: 'row',
    marginLeft: 10,
    width: (Dimensions.get('window').width - 60),
  },
  horizontalInputBox: {
    display: "flex",
    flexDirection: 'row',
    alignContent: "center",
    alignItems: "center",
  },
  checkBoxBox: {
    marginLeft: 20,
    marginRight: 0,
  },
  checkBoxText: {
    marginRight: 0,
    marginLeft: 0,
  },
  checkBoxIcon: {
    marginHorizontal: 3
  },
  checkBoxIconChecked: {
    marginLeft: 2.4,
    marginRight: 3.6
  }
}));

function viewModelUrl(restaurantId, url, width, height) {
  if (!url) {
    return "https://crtsn.com/view/path"
  }
  const idRegex = new RegExp("[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}");
  const id = idRegex.exec(url)[0];
  return "https://crtsn.com/view/path?restaurantId=" + restaurantId + "&itemId=" + id + "&width=" + width + "&height=" + height
}

export function ItemScreen({route, navigation}) {
    const styles = useStyles();
    let { item, restaurantId, section, sectionNames, index } = route.params;
    const [caption, setCaption] = useState(item.caption);
    const [description, setDescription] = useState(item.description);
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState("")
    const [hidden, setHidden] = useState(item.hidden);
    const [soldOut, setSoldOut] = useState(item.soldOut);
    const [currentSection, setCurrentSection] = useState(section);
    const [items, setItems] = useState(sectionNames.map((name) => {
      return {label: name, value: name}
    }));
    const [price, setPrice] = useState(item.price);
    let originalSection = section
    const webViewRef = useRef();
    const [user, setUser, restaurant, setRestaurant, mode, setMode] = useContext(Context);

    useFocusEffect(
      useCallback(() => {
          async function refreshData() {
            item, restaurantId, section, sectionNames, index = route.params
            if (caption == item.caption && description == item.description) {
              webViewRef.current?.reload()
            }
            if (item.urlGltf != null) {
              setUrl(item.urlGltf)
            }
            setCaption(item.caption)
            setDescription(item.description)
            setOpen(false)
            setHidden(item.hidden)
            setSoldOut(item.soldOut)
            setCurrentSection(section)
            setItems(sectionNames.map((name) => {
              return {label: name, value: name}
            }))
            setPrice(item.price)
            originalSection = section
          }
          refreshData()
      }, [route])
    );

    const updateItem = () => {
      let newItemData = {
                          caption: caption,
                          price: price,
                          description: description,
                          hidden: hidden,
                          soldOut: soldOut
                        }
      if (originalSection == currentSection) {
        updateMenuItem(restaurantId, section, index, newItemData, currentSection, index, () => {
          navigation.navigate('Home');
        })
      } else {
        updateMenuItem(restaurantId, section, index, newItemData, currentSection, null, () => {
          navigation.navigate('Home');
        })
      }
    }

    const deleteItem = () => {
      deleteMenuItem(restaurantId, section, index, () => {
        navigation.navigate('Home');
      })
    }

    useEffect(() => {
      setCurrentSection(originalSection);
    }, []);

    return (    
      <TouchableWithoutFeedback onPress={() => setOpen(false)}>
        <View style={styles.box}>
          <Header nav={navigation} leftComponentType="back" rightComponentType="editor"
            restaurantId={restaurantId} section={originalSection} itemIndex={index}
          />
          <KeyboardAvoidingView
            style={{ flex: 1}}
            keyboardVerticalOffset={0}
            behavior={"position"}
          >
            <View style={styles.imageBox}>
              { url && Platform.select({
                  web: <iframe style={styles.itemModel} src={viewModelUrl(restaurantId, url, (Dimensions.get('window').width - 40), Dimensions.get('window').width > 750 ? maxWidth : (Dimensions.get('window').width - 80))}/>,
                  ios: <WebView startInLoadingState={true} ref={(ref) => webViewRef.current = ref} style={styles.itemModel} source={{uri: viewModelUrl(restaurantId, url, (Dimensions.get('window').width - 40), (Dimensions.get('window').width - 80))}} />
                })
              }
            </View>
            <ScrollView style={{ paddingBottom: 50 }} keyboardShouldPersistTaps="handled" scrollEnabled={!open}>
              <View style={styles.horizontalInputBox}>
                <Input style={styles.itemTitle} placeholder='Item name'
                  onChangeText={text => setCaption(text)} value={caption}
                  inputContainerStyle={{borderBottomWidth: 0}}
                  containerStyle={{borderBottomWidth: 0, marginBottom: -10, width: Dimensions.get('window').width - 80}}
                  rightIcon={
                    <Icon
                      name='pencil-sharp'
                      size={22}
                      color='black'
                    />
                  }
                />
                <TouchableOpacity onPress={async () => {
                  const idRegex = new RegExp("[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}");
                  const id = idRegex.exec(url)[0];
                  const itemUrl = "https://crtsn.com/view/embed?restaurantId=" + restaurantId + "&itemId=" + id
                  const supported = await Linking.canOpenURL(itemUrl);
    
                  if (supported) {
                    await Linking.openURL(itemUrl);
                  }
                }}>
                  <Icon
                    name='link-sharp'
                    size={25}
                    color='black'
                    style={{marginTop: -12}}
                  />
                </TouchableOpacity>
              </View>
              <Text style={styles.itemLabel}>Description</Text>
              <Input style={styles.itemDescription} placeholder='Item description'
                onChangeText={text => setDescription(text)}
                value={description}
                multiline numberOfLines={3}
                inputContainerStyle={{borderBottomWidth: 0}}
                containerStyle={{borderBottomWidth: 0, marginBottom: -10}}
                inputStyle={{marginBottom: 0}}/>
              <Text style={styles.itemLabel}>Section</Text>
              <DropDownPicker
                open={open}
                value={currentSection}
                items={items}
                setOpen={setOpen}
                setValue={setCurrentSection}
                setItems={setItems}
                style={styles.dropdown}
                containerStyle={styles.dropdownContainer}
                textStyle={styles.dropdownText}
                dropDownContainerStyle={styles.dropdownItemsContainer}
                placeholder="Select sections"
                multiple={false}
                mode="BADGE"
                listMode="SCROLLVIEW"
              />
              {modeData[mode].showProductControls && <View style={styles.horizontalInputBox}>
                <View>
                  <Text style={styles.itemLabel}>Price</Text>
                  <CurrencyInput style={styles.itemPrice}
                    value={price} onChangeValue={setPrice}
                    prefix="$"
                    delimiter=","
                    separator="."
                    precision={2}
                  />
                </View>
                <CheckBox
                  title="Hidden"
                  checked={hidden}
                  checkedIcon={
                    <Icon
                      name='checkbox-outline'
                      size={20}
                      color='black'
                      style={styles.checkBoxIconChecked}
                    />
                  }
                  uncheckedIcon={
                    <Icon
                      name='square-outline'
                      size={20}
                      color='black'
                      style={styles.checkBoxIcon}
                    />
                  }
                  onPress={() => setHidden(!hidden)}
                  containerStyle={styles.checkBoxBox}
                  textStyle={styles.checkBoxText}
                  checkedColor={"#505050"}
                />
                <CheckBox
                  title="Sold Out"
                  checked={soldOut}
                  checkedIcon={
                    <Icon
                      name='checkbox-outline'
                      size={20}
                      color='black'
                      style={styles.checkBoxIconChecked}
                    />
                  }
                  uncheckedIcon={
                    <Icon
                      name='square-outline'
                      size={20}
                      color='black'
                      style={styles.checkBoxIcon}
                    />
                  }
                  onPress={() => setSoldOut(!soldOut)}
                  containerStyle={styles.checkBoxBox}
                  textStyle={styles.checkBoxText}
                  checkedColor={"#505050"}
                />
              </View>}
              <View style={styles.buttonContainer}>
                <Button
                  title="Save"
                  buttonStyle={styles.saveButton}
                  onPress={updateItem}
                />
                <Button
                  title="Delete"
                  buttonStyle={styles.deleteButton}
                  onPress={deleteItem}
                />
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
        </View>
      </TouchableWithoutFeedback>
    )
  }