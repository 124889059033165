import React, { useCallback, useState, useEffect, useContext } from "react";
import { View, Linking, TouchableOpacity, Dimensions, KeyboardAvoidingView, ScrollView, FlatList } from 'react-native';
import { Text, Button, Image, Input, Switch, makeStyles } from '@rneui/themed';
import { Header } from './Header';
import Icon from 'react-native-vector-icons/Ionicons';
import { getMenu, getRestaurant, updateRestaurant, deleteUser } from '../js/cartesanApi'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Context } from "./GlobalContext.js";
import Toast from 'react-native-root-toast';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
    width: "100%",
    backgroundColor: theme.colors.background,
  },
  contentBox: {
    marginLeft: 10
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 15,
    marginLeft: 10,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 20,
  },
  description: {
    fontSize: 14,
    marginBottom: 10,
  },
  link: {
    color: "#8A8686",
  },
  deleteButtonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15
  },
  deleteButton: {
    width: 175,
  },
  deleteSwitchBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 30,
  },
  deleteSwitchText: {
    marginRight: 10,
  }
}));

export function SettingsScreen({route, navigation}) {
    const styles = useStyles();

    const [user, setUser, restaurant, setRestaurant] = useContext(Context);
    const restaurantId = user.restaurant_ids[0]
    const [enableDelete, setEnableDelete] = useState(false)

    const deleteAccount = () => {
        deleteUser(user.user_id, () => {
            try {
                AsyncStorage.removeItem('@Cartesan_user').then(() => {
                    navigation.navigate("Login")
                    let toast = Toast.show("Account deleted", {
                        duration: Toast.durations.LONG + 5000,
                        position: Toast.positions.BOTTOM - 10,
                        shadow: false,
                        animation: true,
                        hideOnPress: true,
                        delay: 500,
                      });
                })
            } catch(e) {
                console.log(e)
            }
        })
        
    }

    return (    
      <View style={styles.box}>
        <Header nav={navigation} leftComponentType="menu" rightComponentType="none"/>
        <KeyboardAvoidingView
          style={{flexGrow: 1}}
          keyboardVerticalOffset={-200}
          behavior={"position"}
        >
          <View>
            <Text style={styles.title}>Account Settings</Text>
            <View style={styles.contentBox}>
                <Text style={styles.subtitle}>Account Information</Text>
                <Text style={styles.description}>Account name: {user.user_name}</Text>
                <Text style={styles.description}>Contact email: {user.email}</Text>
                <Text style={styles.description}>Subscription status: {user.active_subscription ? "Active" : "Not active"}</Text>
                <Text style={styles.subtitle}>Manage Account</Text>
                <Text style={styles.description}>Visit 
                    <Text
                        style={styles.link}
                        onPress={() => Linking.openURL("https://cartesan.app/login")}>
                        {' '}cartesan.app{' '}
                    </Text>
                    to manage your account.
                </Text>
                <Text style={styles.subtitle}>Delete Account</Text>
                <Text style={styles.description}>Deleting your account is permanent. All user data including menu data, restaurant data, and account data will be removed.</Text>
                <View style={styles.deleteButtonBox}>
                    <Button disabled={!enableDelete} color="#F22613" buttonStyle={styles.deleteButton} width={200} onPress={deleteAccount}>Delete Account</Button>
                    <View style={styles.deleteSwitchBox}>
                        <Text style={styles.deleteSwitchText}>Allow</Text>
                        <Switch
                            value={enableDelete}
                            onValueChange={(value) => setEnableDelete(value)}
                        />
                    </View>
                </View>
            </View>
          </View>
        </KeyboardAvoidingView>
      </View>
    )
  }