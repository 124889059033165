import React, { useCallback, useState, useRef, useContext, useEffect } from "react";
import { View, TouchableOpacity, Dimensions, Platform, Linking, Image } from 'react-native';
import { Text, makeStyles, Badge, Overlay, Button } from '@rneui/themed';
import { Header } from './Header';
import Icon from 'react-native-vector-icons/Ionicons';
import { Camera, CameraType } from 'expo-camera';
import { createModel, getRestaurant } from '../js/cartesanApi';
import Toast from 'react-native-root-toast';
import AnimatedLoader from "react-native-animated-loader";
import LottieViewNative from 'lottie-react-native';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { activateKeepAwake, deactivateKeepAwake } from 'expo-keep-awake';
import { Context } from "./GlobalContext.js";

var LottieViewWeb = null
if (Platform.OS == 'web') {
    LottieViewWeb = require('react-native-web-lottie').default
}

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexGrow: 1,
    paddingTop: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.background,
  },
  camera: {
    height: "100%",
    width: "100%",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 5
  },
  description: {
    textAlign: "center",
  },
  cameraPrompts: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 15,
  },
  cameraTextView: {
    backgroundColor: "white",
    padding: 10,
    borderRadius: 8,
    overflow: "hidden",
    color: "black",
    opacity: 0.55
  },
  buttonContainer: {
    position: "absolute",
    bottom: 50,
    left: (Dimensions.get('window').width - 300) / 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    alignItems: "center"
  },
  photosButton: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    padding: 10,
    paddingRight: 5,
    borderRadius: 100,
    overflow: "hidden",
    color: "black",
    opacity: 0.55,
    justifyContent: "center",
    alignItems: "center",
  },
  photosButtonBadge: {
    marginLeft: -10,
    marginTop: -20,
    backgroundColor: "grey"
  },
  cameraModeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#DDDDDD",
    borderRadius: 8,
    overflow: "hidden",
    marginBottom: 10
  },
  cameraModeButtonSelected: {
    width: 100,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    padding: 8,
    backgroundColor: "#BBBBBB",
    flexGrow: 1,
  },
  cameraModeButton: {
    width: 100,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    padding: 8,
    flexGrow: 1
  },
  cameraModeButtonTextSelected: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    fontWeight: "bold"
  },
  cameraModeButtonText: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    margin: "auto"
  },
  cameraButton: {
    backgroundColor: "white",
    padding: 10,
    borderRadius: 100,
    overflow: "hidden",
    color: "black",
    opacity: 0.55,
    marginHorizontal: 65,
  },
  doneButton: {
    backgroundColor: "white",
    padding: 10,
    borderRadius: 100,
    overflow: "hidden",
    color: "black",
    opacity: 0.55
  },
  permissionsBox: {
    margin: 20
  },
  loaderBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: 100
  },
  loader: {
    width: 200,
    height: 200,
  },
  loadingText: {
    fontSize: 18,
    fontWeight: "bold"
  },
  selectButton: {
    width: 225,
    marginBottom: 5
  },
  selectBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectDescription: {
    marginBottom: 10,
  },
  recordingInfoBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    backgroundColor: "white",
    opacity: 0.55,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 8
  },
  recordingInfoCircle: {
    backgroundColor: "red",
    width: 12,
    height: 12,
    borderRadius: 50,
    marginRight: 5
  },
  helpBox: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  helpBoxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 235,
  },
  helpBoxContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    opacity: 0.8,
    padding: 15,
    width: Dimensions.get('window').width - 60,
    borderRadius: 8
  },
  helpBoxTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 5,
    marginBottom: 20
  },
  helpBoxDesc: {
    fontSize: 15,
    marginBottom: 10,
    textAlign: "left",
    marginRight: "auto",
    paddingHorizontal: 10,
  },
  helpBoxButtonBox: {
    display: "flex",
    flexDirection: "row"
  },
  helpBoxButtonPrimary: {
    fontSize: 15,
    backgroundColor: "#BBBBBB",
    marginTop: 10,
    paddingVertical: 12,
    paddingHorizontal: 18,
    borderRadius: 8,
    marginHorizontal: 5
  },
  helpBoxButtonSecondary: {
    fontSize: 15,
    backgroundColor: "#DDDDDD",
    marginTop: 10,
    paddingVertical: 12,
    paddingHorizontal: 18,
    borderRadius: 8,
    marginHorizontal: 5
  },
  scanImage: {
    width: 200,
    height: 300,
    marginTop: 20,
    marginBottom: 5,
    borderRadius: 8
  },
  scanImageMobile: {
    width: 120,
    height: 180,
    marginVertical: 5,
    borderRadius: 8
  }
}));

const tutorialUrl = "https://cartesan.app/tutorial"

export function CameraScreen({route, navigation}) {
    const styles = useStyles();
    const web = Platform.OS === 'web'
    let LottieView
    if (web) {
      LottieView = LottieViewWeb
    } else {
      LottieView = LottieViewNative
    }

    const [permission, requestPermission] = Platform.OS === 'web' ? [null, null] : Camera.useCameraPermissions();
    const [type, setType] = useState(CameraType.back);
    const [camera, setCamera] = useState(null);
    const [cameraAccess, setCameraAccess] = useState(null);
    const [cameraMode, setCameraMode] = useState('video')
    const [cameraTips, setCameraTips] = useState(true);
    const [recording, setRecording] = useState(false)
    const [recordingTimer, setRecordingTimer] = useState(null)
    const [recordingDuration, setRecordingDuration] = useState(0)
    const [cameraDisabled, setCameraDisabled] = useState(false);
    const [loadingFromLibrary, setLoadingFromLibrary] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [generating, setGenerating] = useState(false);
    const [images, setImages] = useState([])
    const [imagesLength, setImagesLength] = useState(0)
    const [selectedImages, setSelectedImages] = useState([])
    const [user, setUser, restaurant, setRestaurant] = useContext(Context);
    const restaurantId = user?.restaurant_ids[0]

    var successToast = null
    var error = false

    const checkCameraPermissions = async () => {
      if (Platform.OS === 'web') {
        setCameraAccess(true);
        return
      }
      if (permission == null || (!permission.granted && permission.canAskAgain)) {
        const cameraPermission = await Camera.requestCameraPermissionsAsync();
        setCameraAccess(cameraPermission.status === 'granted');
      }
    };

    const pickImages = async () => {
      if (web) {
        let result = await DocumentPicker.getDocumentAsync({
          multiple: true,
          type: ["*/*"]
        });

        let selectedImages = []
        if (!result.canceled) {
          for (let image = 0; image < result.output.length; image++) {
            selectedImages.push(result.output.item(image))
          }
          setSelectedImages(selectedImages)
        }
        return
      }

      setLoadingFromLibrary(true)

      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync({
        allowsMultipleSelection: true,
        exif: true,
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        presentationStyle: ImagePicker.UIImagePickerPresentationStyle.AUTOMATIC,
        aspect: [4, 3],
        quality: 0.5,
      });

      let selectedImages = []
      if (!result.canceled) {
        for (let image in result.assets) {
          selectedImages.push(result.assets[image].uri)
        }
        setSelectedImages(selectedImages)
      }
      setLoadingFromLibrary(false)
    };

    useEffect(() => {
      checkCameraPermissions();
    }, []);

    const showToast = (message) => {
      if (successToast != null) {
        Toast.hide(successToast)
      }
      let toast = Toast.show(message, {
        duration: Toast.durations.LONG + 8500,
        position: Toast.positions.BOTTOM - 10,
        shadow: false,
        animation: true,
        hideOnPress: true,
        delay: 500,
        onShow: () => {
            // calls on toast\`s appear animation start
        },
        onShown: () => {
            // calls on toast\`s appear animation end.
        },
        onHide: () => {
            // calls on toast\`s hide animation start.
        },
        onHidden: () => {
            // calls on toast\`s hide animation end.
        }
      });
    }

    const doneCreateModel = () => {
      showToast('Model generation complete! See menu screen.')
      deactivateKeepAwake()
      async function refreshData() {
        getRestaurant(restaurantId, async (data) => {
            setRestaurant(data)
            // await new Promise(r => setTimeout(r, 5000))
            setGenerating(false)
            navigation.navigate("Home")
        })
      }
      refreshData()
    }

    const errorCreateModel = (data) => {
      showToast('Model generation failed!')
      setGenerating(false)
      error = true
      deactivateKeepAwake()
      navigation.navigate("Home")
    }

    const updateUploadProgress = (progress) => {
      setUploadProgress(progress)
    }

    const requestCreateModel = async () => {
      error = false
      let totalImages = images.concat(selectedImages)
      setImagesLength(totalImages.length)

      if (totalImages.length < 0) {
        showToast("Not enough photos! (30 or more required)")
        return
      }

      // Start keep screen awake
      activateKeepAwake()

      // Request create model from API
      setUploading(true)
      setUploadProgress(0)
      await createModel(restaurantId, totalImages, Platform.OS !== 'web', doneCreateModel, updateUploadProgress, errorCreateModel)
      setUploading(false)

      // Reset stored images
      setImages([])
      setSelectedImages([])

      // Set timeout for API call
      new Promise(r => setTimeout(r, 1000 * 60 * 20)).then(() => {
        setGenerating(false)
        setUploaded(false)
      }) 

      // Display uploaded animation for brief time
      setUploaded(true)
      await new Promise(r => setTimeout(r, 2500))
      setUploaded(false) 

      if (!error) {
        // Add a Toast on screen.
        successToast = Toast.show('Generating model (3-5 min)', {
          duration: Toast.durations.LONG + 5000,
          position: Toast.positions.BOTTOM - 10,
          shadow: false,
          animation: true,
          hideOnPress: true,
          delay: 500,
          onShow: () => {
              // calls on toast\`s appear animation start
          },
          onShown: () => {
              // calls on toast\`s appear animation end.
          },
          onHide: () => {
              // calls on toast\`s hide animation start.
          },
          onHidden: () => {
              // calls on toast\`s hide animation end.
          }
        });
        setGenerating(true)
      }
    }

    useEffect(() => {
      if (recording) {
        setRecordingTimer(setInterval(() => {
          setRecordingDuration(recordingDuration => recordingDuration + 1)
        }, 1000))
      } else {
        if (recordingTimer) {
          clearInterval(recordingTimer)
        }
      }
    }, [recording])

    async function takePhoto() {
      if (camera && cameraMode === 'photo') {
        setCameraDisabled(true)
        camera.takePictureAsync().then((data) => {
          //console.log(data.uri.split('/').at(-1))
          //let file = dataURLtoFile('data:image/jpg;base64,' + data.base64, data.uri.split('/').at(-1))
          //console.log(file)t
          setImages(images.concat([data.uri]));
          setCameraDisabled(false)
        });
      } else if (camera && cameraMode === 'video') {
        if (recording) {
          camera.stopRecording()
          setRecordingDuration(0)
          setRecording(false)
        } else {
          setCameraDisabled(true)
          setRecording(true)
          setRecordingDuration(0)
          camera.recordAsync(options={mute: true}).then((data) => {
            //console.log(data.uri.split('/').at(-1))
            //let file = dataURLtoFile('data:image/jpg;base64,' + data.base64, data.uri.split('/').at(-1))
            //console.log(file)
            setImages(images.concat([data.uri]));
          });
          setCameraDisabled(false)
        }
      }
    }

    if (!cameraAccess) {
      return (
        <View style={styles.box}>
          <Header nav={navigation} leftComponentType="back" rightComponentType="none" transparency={false}/>
          <View style={styles.permissionsBox}>
            <Text style={styles.title}>Scan Menu Item</Text> 
            <Text style={styles.description}>Camera permissions required:</Text> 
            <Text style={styles.description}>Navigate to Settings > Cartesan</Text> 
            <Text style={styles.description}>Enable the Camera permission</Text> 
          </View>
        </View>
      )
    }

    if (loadingFromLibrary) {
      return (
        <View style={styles.box}>
          <Header nav={navigation} leftComponentType="back" rightComponentType="none" transparency={false}/>
          <View style={styles.loaderBox}>
            <LottieView
              autoPlay
              loop
              style={styles.loader}
              source={require('../assets/spoon-circle.json')}
            />
            <Text style={styles.loadingText}>Loading files...</Text>
          </View>
        </View>
      )
    }

    if (generating) {
      return (
        <View style={styles.box}>
          <Header nav={navigation} leftComponentType="back" rightComponentType="none" transparency={false}/>
          <View style={styles.loaderBox}>
            <LottieView
              autoPlay
              loop
              style={styles.loader}
              source={require('../assets/bowl-loader.json')}
            />
            <Text style={styles.loadingText}>Model Generating (3-5 min)...</Text>
          </View>
        </View>
      )
    }


    if (web) {
      return (
        <View style={styles.box}>
          <View style={styles.camera} type={type}>
            <Header nav={navigation} leftComponentType="back" rightComponentType="none" />
            <View style={styles.cameraPrompts}>
              <Text style={styles.title}>Add Menu Item</Text> 
              <Text style={styles.description}>Upload a ~30 second video or 30-40 pictures of your item.</Text> 
              <Text style={styles.description}>Photograph from all different angles.</Text> 
              <Text style={styles.description}>For capture tips, visit cartesan.app/tutorial.</Text>
              <Image style={styles.scanImage} source={require('../assets/bagel.gif')}></Image>
              <Text style={styles.description}>Example video</Text>
            </View>
            <View style={styles.selectBox}>
              {selectedImages.length > 0 && 
                <Text style={styles.selectDescription}>{selectedImages.length} files selected.</Text> 
              }
              <Button buttonStyle={styles.selectButton} onPress={pickImages} color="grey">Select Files</Button>
              <Button disabled={selectedImages.length == 0 && !uploading && !generating} buttonStyle={styles.selectButton} onPress={requestCreateModel}>Generate Model</Button>
            </View>
          </View>
        </View>
      )
    }
    
    return (    
      <View style={styles.box}>
        <Camera ref={(ref) => setCamera(ref)} style={styles.camera} type={type} defaultRecordSettings={{mute: true}}>
            <Header nav={navigation} leftComponentType="back" rightComponentType="help" rightComponentCallback={() => setCameraTips(!cameraTips)} transparency={true}/>
            <AnimatedLoader
              visible={uploading}
              overlayColor="rgba(255,255,255,0.5)"
              source={require("../assets/hamburger-loader.json")}
              animationStyle={styles.loader}
              speed={1}
              loop={true}
            >
              <Text style={styles.loadingText}>Uploading images...</Text>
              <Text style={styles.loadingText}>{Math.floor(100 * uploadProgress)}% complete</Text>
            </AnimatedLoader>
            <AnimatedLoader
              visible={uploaded}
              overlayColor="rgba(255,255,255,0.5)"
              source={require("../assets/complete.json")}
              animationStyle={styles.loader}
              speed={1}
              loop={false}
            >
              <Text style={styles.loadingText}>Finished upload!</Text>
            </AnimatedLoader>
            {cameraTips && <View style={styles.helpBox}>
              <View style={styles.helpBoxContent}>
                <View style={styles.helpBoxContainer}>
                  <Text style={styles.helpBoxTitle}>Scanning Tips</Text>
                  <Text style={styles.helpBoxDesc}>Take a 30 second video by circling around and covering every angle.</Text>
                  <Text style={styles.helpBoxDesc}>Avoid shiny, reflective, and watery materials.</Text>
                  <Text style={styles.helpBoxDesc}>Remove other objects from the camera frame.</Text>
                  <Image style={styles.scanImageMobile} source={require('../assets/bagel.gif')}></Image>
                  <View style={styles.helpBoxButtonBox}>
                    <TouchableOpacity style={styles.helpBoxButtonPrimary} onPress={() => setCameraTips(false)}>
                      <Text>Got it!</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.helpBoxButtonSecondary} onPress={() => Linking.openURL(tutorialUrl)}>
                      <Text>More tips</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>}
            <View style={styles.cameraPrompts}>
                <View style={styles.cameraTextView}>
                    <View style={styles.cameraModeContainer}>
                      <TouchableOpacity style={cameraMode === 'video' ? styles.cameraModeButtonSelected : styles.cameraModeButton}
                        onPress={() => setCameraMode('video')}
                      >
                        <Text style={cameraMode === 'video' ? styles.cameraModeButtonTextSelected : styles.cameraModeButtonText}>Video</Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={cameraMode === 'photo' ? styles.cameraModeButtonSelected : styles.cameraModeButton}
                        onPress={() => setCameraMode('photo')}
                      >
                        <Text style={cameraMode === 'photo' ? styles.cameraModeButtonTextSelected : styles.cameraModeButtonText}>Photo</Text>
                      </TouchableOpacity>
                    </View>
                    {cameraMode === 'video' && <Text style={styles.description}>Record a video capturing every angle</Text> }
                    {cameraMode === 'photo' && <Text style={styles.description}>Take 30-40 photos from every angle</Text> }
                </View>
                {recording && 
                  <View style={styles.recordingInfoBox}>
                    <View style={styles.recordingInfoCircle}/>
                    <Text style={styles.recordingText}>{Math.floor(recordingDuration / 60)}:{recordingDuration < 10 ? '0' + recordingDuration : recordingDuration}</Text>
                  </View>
                }
            </View>
            <View style={styles.buttonContainer}>
              <TouchableOpacity style={styles.photosButton} onPress={pickImages}>
                  <Icon name={cameraMode === 'video' ? "film-outline" : "image-outline"} size={30} color="black" disabled={recording}/>
                  <Badge badgeStyle={styles.photosButtonBadge} value={images.length + selectedImages.length} status="success" />
              </TouchableOpacity>
              <TouchableOpacity style={styles.cameraButton} onPress={takePhoto} disabled={cameraDisabled}>
                  <Icon name={cameraMode === 'video' ? "videocam-outline" : "camera-outline"} size={50} color="black" />
              </TouchableOpacity>
              <TouchableOpacity style={styles.doneButton} onPress={requestCreateModel} disabled={recording}>
                  <Icon name="checkmark-outline" size={30} color="black" />
              </TouchableOpacity>
            </View>
        </Camera>
      </View>
    )
  }