import React, { useCallback, useState, useContext, useEffect } from "react";
import { View, Linking, TouchableOpacity, Dimensions, ScrollView } from 'react-native';
import { Text, Image, makeStyles, Skeleton } from '@rneui/themed';
import { THREE } from 'expo-three';
import Icon from 'react-native-vector-icons/Ionicons';
import { Header } from './Header';
import { Context, modeData } from "./GlobalContext.js";
import { getMenu, getRestaurant, getUser } from '../js/cartesanApi'
import { useFocusEffect } from '@react-navigation/native';

global.THREE = global.THREE || THREE;

const paddedScreenWidth = Dimensions.get('window').width - 60
const maxPreviewWidth = 250

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        flexGrow: 1,
        padding: 20,
        paddingHorizontal: 0,
        paddingTop: 0,
        width: "100%",
        backgroundColor: theme.colors.background,
    },
    headerBox: {
        paddingLeft: 20,
        paddingRight: 20
    },
    titleText: {
        textAlign: 'left',
        fontSize: 30
    },
    descText: {
        textAlign: 'left',
        fontSize: 16
    },
    loginTitle: {
        fontSize: 16
    },
    loginButtonBox: {
        margin: 10,
        display: 'flex',
        alignItems: 'center',
    },
    loginButton: {
        padding: 5,
        width: 200,
    },
    menuPreview: {
        marginBottom: 10,
        marginTop: 10,
        marginLeft: 20
    },
    itemPreview: {
        marginRight: 20,
        marginBottom: 20,
        width: paddedScreenWidth / 2,
        maxWidth: maxPreviewWidth,
        backgroundColor: "#E0E0E0",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        overflow: "hidden"
    },
    itemPreviewNoSpace: {
        marginRight: 20,
        marginBottom: 20,
        width: paddedScreenWidth / 2,
        maxWidth: maxPreviewWidth,
        backgroundColor: "#E0E0E0",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        overflow: "hidden"
    },
    itemPreviewTitle: {
        textAlign: "center",
        margin: 10,
        fontSize: 14,
    },
    itemPreviewTitlePlaceholder: {
        textAlign: "center",
        margin: 10,
        fontSize: 14,
        color: "#909090"
    },
    itemPreviewImage: {
        width: paddedScreenWidth / 2,
        height: paddedScreenWidth / 2,
        maxWidth: maxPreviewWidth,
        maxHeight: maxPreviewWidth,
        margin: "auto",
        resizeMode: 'cover',
    },
    itemPreviewBackground: {
        backgroundColor: '#F0F0F0',
    },
    menuSection: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
    },
    menuSectionTitle: {
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 15,
        marginTop: 15,
    },
    menuTitleBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        marginTop: 15,
        marginBottom: 0,
    },
    menuTitle: {
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "center",
        marginRight: 5
    },
    menuDescriptionBox: {
        marginTop: 20
    },
    menuDescription: {
        fontSize: 16,
        textAlign: "center",
    },
    scroll: {
        paddingBottom: 120,
    },
    skeletonBoxContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: 20
    },
    skeletonBox: {
        marginRight: 20,
        marginBottom: 20,
        width: paddedScreenWidth / 2,
        height: paddedScreenWidth / 2 + 25,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        overflow: "hidden",
        opacity: 0.25,
    },
    skeletonBoxNoSpacing: {
        marginRight: 0,
        marginBottom: 20,
        width: paddedScreenWidth / 2,
        height: paddedScreenWidth / 2 + 25,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        overflow: "hidden",
        opacity: 0.25
    },
    skeletonSubtitle: {
        width: paddedScreenWidth / 1.5,
        height: 24,
        borderRadius: 8,
        marginVertical: 10,
        opacity: 0.25,
        marginLeft: 20
    },
    noItemsBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        marginTop: 20,
    },
    noItemsDescription: {
        fontSize: 14,
    }
}));

function MenuItemPreview(props) {
    const styles = useStyles();

    const itemCaption = props.item.caption
    const itemSection = props.section
    const itemUrl = props.item.urlGltf
    const itemPreviewUrl = props.item.urlPng
    const navigation = props.nav
    const [modelData, setModelData] = useState("none")
    const [modelPreview, setModelPreview] = useState(itemPreviewUrl)

    const pressed = () => {
        navigation.navigate("Item", {
            item: props.item,
            restaurantId: props.restaurantId,
            section: itemSection,
            sectionNames: props.sectionNames,
            index: props.index
        });
    }

    if (modelPreview == null) {
        return (
            <View>
            </View>
        )
    }

    return (
        <TouchableOpacity style={props.index % 2 == 0 ? styles.itemPreview : styles.itemPreviewNoSpace} onPress={pressed}>
            <View style={styles.itemPreviewBackground}>
                <Image style={styles.itemPreviewImage} source={{uri: modelPreview}}/>
            </View>
            {itemCaption != "" ? 
                <Text style={styles.itemPreviewTitle} numberOfLines={1}>{itemCaption}</Text>:
                <Text style={styles.itemPreviewTitlePlaceholder} numberOfLines={1}>Empty Name</Text>
            }
        </TouchableOpacity>
    )
}

function MenuSectionPreview(props) {
    const styles = useStyles();

    const sectionName = props.name
    const sectionItems = props.items
    const modelDataCache = props.cache

    return (
        <View>
            <Text style={styles.menuSectionTitle}>{sectionName}</Text>
            <View style={styles.menuSection}>
                {sectionItems.map((item, index) => {
                    return (
                        <MenuItemPreview key={item.name + index + item.urlPng} cache={modelDataCache} item={item} nav={props.nav}
                            restaurantId={props.restaurantId} section={sectionName} sectionNames={props.sectionNames}
                            index={index}/>
                    )
                })}
            </View>
        </View>
    )
}

function MenuPreview(props) {
    const styles = useStyles();

    const menuData = props.menu.items
    const sections = props.sectionNames
    const modelDataCache = props.cache

    return (
        <View style={styles.menuPreview}>
            {sections.filter((index) => Object.keys(menuData).includes(index)).map((sectionName) => {
                return (
                    <MenuSectionPreview key={sectionName} cache={modelDataCache} name={sectionName} items={menuData[sectionName]} nav={props.nav}
                        restaurantId={props.restaurantId} sectionNames={props.sectionNames}/>
                )
            })}
        </View>
    )
}

export function HomeScreen({navigation}) {
    const styles = useStyles();
    const [user, setUser, restaurant, setRestaurant, mode, setMode] = useContext(Context);
    const [menu, setMenu] = useState(null);
    const restaurantId = user?.restaurant_ids[0]
    const modelDataCache = {}
    const menuUrl = "https://crtsn.com/menu/" + restaurantId
    const setupUrl = "https://cartesan.app"

    const openMenuLink = async () => {
        // Checking if the link is supported for links with custom URL scheme.
        const supported = await Linking.canOpenURL(menuUrl);
    
        if (supported) {
          // Opening the link with some app, if the URL scheme is "http" the web link should be opened
          // by some browser in the mobile
          await Linking.openURL(menuUrl);
        } else {
          Alert.alert(`Don't know how to open this URL: ${menuUrl}`);
        }
    }

    const openSetupLink = async () => {
        // Checking if the link is supported for links with custom URL scheme.
        const supported = await Linking.canOpenURL(setupUrl);
    
        if (supported) {
          // Opening the link with some app, if the URL scheme is "http" the web link should be opened
          // by some browser in the mobile
          await Linking.openURL(setupUrl);
        } else {
          Alert.alert(`Don't know how to open this URL: ${setupUrl}`);
        }
    }

    useFocusEffect(
        useCallback(() => {
            async function refreshData() {
                await new Promise(r => setTimeout(r, 325))
                getRestaurant(restaurantId, (data) => {
                    setRestaurant(data)
                    setMenu(data.menu)
                })
            }
            refreshData()
        }, [user])
    );
    
    useEffect(() => {
        setMenu(restaurant?.menu)
    }, [restaurant])

    if (!user) {
        return <View>
            <Text>{user}</Text>
            <Text>{user == null ? "SDF" : "SDFG"}</Text>

            <Text>{"user"}</Text>
        </View>
    }

    if (!user.active_subscription) {
        return (
            <View style={styles.box}>
                <View style={styles.headerBox}>
                    <Header restaurantId={restaurantId} nav={navigation} leftComponentType="menu" rightComponentType="add"/>
                </View>
                <ScrollView>
                    <View style={styles.menuTitleBox}>
                        <Text style={styles.menuTitle}>Setup your account</Text>
                        <TouchableOpacity onPress={openSetupLink}>
                            <Icon name="open-outline" size={25} color="black"/>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.menuDescriptionBox}>
                        <Text style={styles.menuDescription}>No subscription license found.</Text>
                        <Text style={styles.menuDescription}>Visit cartesan.app to register.</Text>
                    </View>
                </ScrollView>
            </View>
        )
    }

    // Handle menu is not yet loaded case
    if (restaurant == null && menu == null) {
        return (
            <View style={styles.box}>
                <View style={styles.headerBox}>
                    <Header restaurantId={restaurantId} nav={navigation} leftComponentType="menu" rightComponentType="add"/>
                </View>
                <ScrollView>
                    <Skeleton style={styles.skeletonSubtitle}></Skeleton>
                    <View style={styles.skeletonBoxContainer}>
                        <Skeleton style={styles.skeletonBox}></Skeleton>
                        <Skeleton style={styles.skeletonBoxNoSpacing}></Skeleton>
                    </View>
                    <Skeleton style={styles.skeletonSubtitle}></Skeleton>
                    <View style={styles.skeletonBoxContainer}>
                        <Skeleton style={styles.skeletonBox}></Skeleton>
                        <Skeleton style={styles.skeletonBoxNoSpacing}></Skeleton>
                    </View>
                </ScrollView>
            </View>
        )
    }

    // Handle restaurant is loaded but menu has no items case
    if (restaurant != null && menu == null) {
        return (
            <View style={styles.box}>
                <View style={styles.headerBox}>
                    <Header restaurantId={restaurantId} nav={navigation} leftComponentType="menu" rightComponentType="add"/>
                </View>
                <ScrollView>
                    <View style={styles.menuTitleBox}>
                        <Text style={styles.menuTitle}>{restaurant.name} {modeData[mode].collectionNoun}</Text>
                        <TouchableOpacity onPress={openMenuLink}>
                            <Icon name="open-outline" size={25} color="black"/>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.noItemsBox}>
                        <Text style={styles.noItemsDescription}>No items added.</Text>
                        <Text style={styles.noItemsDescription}>To add an item, press the + icon.</Text>
                    </View>
                </ScrollView>
            </View>
        )
    }

    return (
      <View style={styles.box}>
        <View style={styles.headerBox}>
            <Header restaurantId={restaurantId} nav={navigation} leftComponentType="menu" rightComponentType="add"/>
        </View>
        <ScrollView>
            <View style={styles.menuTitleBox}>
                <Text style={styles.menuTitle}>{restaurant.name} {modeData[mode].collectionNoun}</Text>
                <TouchableOpacity onPress={openMenuLink}>
                    <Icon name="open-outline" size={25} color="black"/>
                </TouchableOpacity>
            </View>
            <MenuPreview menu={menu} cache={modelDataCache} nav={navigation} restaurantId={restaurantId} sectionNames={restaurant.sections}/>
            <View style={styles.scroll}></View>
        </ScrollView>
      </View>
    )
  }