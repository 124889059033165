import React, { useCallback, useState, useEffect, useContext } from "react";
import { View, Linking, TouchableOpacity, Dimensions, KeyboardAvoidingView, ScrollView, Keyboard, TouchableWithoutFeedback, Platform } from 'react-native';
import { Text, Button, Image, Input, makeStyles } from '@rneui/themed';
import { Header } from './Header';
import Icon from 'react-native-vector-icons/Ionicons';
import { getMenu, getRestaurant, updateRestaurant, deleteUser } from '../js/cartesanApi'
import { useFocusEffect } from '@react-navigation/native';
import { Context, modeData } from "./GlobalContext.js";
import { DraxProvider, DraxList } from 'react-native-drax';

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
    width: "100%",
    backgroundColor: theme.colors.background,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 15,
    marginLeft: 10
  },
  subtitleBox: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 10,
    marginVertical: 10,
  },
  subtitle: {
    fontSize: 20,
    flexGrow: 1
  },
  itemTitle: {
    fontSize: 22,
    borderBottomWidth: 0,
    marginBottom: 20,
  },
  itemLabel: {
    fontSize: 14,
    marginBottom: 5,
    marginLeft: 10
  },
  itemDescription: {
    fontSize: 16,
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 10,
    paddingTop: 13,
    paddingBottom: 13,
  },
  sectionBox: {
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    height: 250,
    borderWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
    borderRadius: 8,
    marginHorizontal: 10,
    marginBottom: 10,
  },
  sectionList: {
    marginHorizontal: 10,
    paddingBottom: 10,
    paddingTop: 10,
  },
  sectionText: {
    fontSize: 16,
    paddingTop: 3,
    paddingLeft: 10,
    backgroundColor: "#E5E5E5",
    borderRadius: 8,
    overflow: "hidden",
    paddingBottom: 1,
    marginBottom: 5,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  saveButton: {
    backgroundColor: "#2196F3",
    fontSize: 16,
    width: (Dimensions.get('window').width - 80) / 2,
    marginRight: 20
  },
  deleteButton: {
    backgroundColor: "#9E9E9E",
    fontSize: 16,
    width: (Dimensions.get('window').width - 70) / 2
  },
  buttonContainer: {
    display: "flex",
    flexDirection: 'row',
    marginLeft: 10,
    width: (Dimensions.get('window').width - 60),
  },
  menuTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    marginTop: 15,
    marginBottom: 0,
  },
  menuTitle: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginRight: 5
  },
  menuDescriptionBox: {
    marginTop: 20
  },
  menuDescription: {
    fontSize: 16,
    textAlign: "center",
  },
  subscriptionText: {
    marginLeft: 10,
    fontSize: 16
  }
}));

export function PreferencesScreen({route, navigation}) {
    const styles = useStyles();

    const [user, setUser, restaurant, setRestaurant, mode, setMode] = useContext(Context);
    const restaurantId = user.restaurant_ids[0]
    const [restaurantName, setRestaurantName] = useState(restaurant.name)
    const [address, setAddress] = useState(restaurant.address)
    const [sections, setSections] = useState(restaurant.sections)
    const [listKey, setListKey] = useState("KEY")


    useFocusEffect(
      useCallback(() => {
          async function refreshData() {
            setSections(restaurant.sections)
            getRestaurant(restaurantId, (data) => {
              setRestaurant(data)
              setSections(data.sections)
            })
          } 
          refreshData()
      }, [route])
    );

    const updateRestaurantData = () => {
      let data = {
          name: restaurantName,
          address: address,
          sections: sections
      }
      updateRestaurant(restaurantId, data)
      navigation.navigate("Home")
    }

    const addSection = () => {
      setSections(sections.concat([""]))
    }

    const renameSection = (index, name) => {
      setSections(sections.slice(0, index).concat([name], sections.slice(index + 1, sections.length)))
    }

    const deleteSection = (index) => {
      setSections(sections.filter((element, ind) => {
          return ind != index
      }))
    }

    if (!user.active_subscription) {
        const openSetupLink = async () => {
            // Checking if the link is supported for links with custom URL scheme.
            const supported = await Linking.canOpenURL(setupUrl);
        
            if (supported) {
              // Opening the link with some app, if the URL scheme is "http" the web link should be opened
              // by some browser in the mobile
              await Linking.openURL(setupUrl);
            } else {
              Alert.alert(`Don't know how to open this URL: ${setupUrl}`);
            }
        }

        return (
            <View style={styles.box}>
                <Header restaurantId={restaurantId} nav={navigation} leftComponentType="menu" rightComponentType="none"/>
                <ScrollView>
                    <View style={styles.menuTitleBox}>
                        <Text style={styles.menuTitle}>Setup your account</Text>
                        <TouchableOpacity onPress={openSetupLink}>
                            <Icon name="open-outline" size={25} color="black"/>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.menuDescriptionBox}>
                        <Text style={styles.menuDescription}>No subscription license found.</Text>
                        <Text style={styles.menuDescription}>Visit cartesan.app to register.</Text>
                    </View>
                </ScrollView>
            </View>
        )
    }

    return (    
      <View style={styles.box}>
        <TouchableWithoutFeedback onPress={Platform.OS === 'web' ? null : Keyboard.dismiss}>
          <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={-50}>
            <Header nav={navigation} leftComponentType="menu" rightComponentType="none"/>
            <Text style={styles.title}>Account Preferences</Text>
            <Input style={styles.itemTitle} placeholder='Store name'
              onChangeText={text => setRestaurantName(text)} value={restaurantName}
              inputContainerStyle={{borderBottomWidth: 0, marginBottom: 0}} errorStyle={{display: "none"}}
              rightIcon={
                <Icon
                  name='pencil-sharp'
                  size={22}
                  color='black'
                />
              }
              inputStyle={{marginBottom: 0}}/>
            <Text style={styles.itemLabel}>Address</Text>
            <Input style={styles.itemDescription} placeholder='Store address'
              onChangeText={text => setAddress(text)} value={address}
              multiline numberOfLines={3} inputContainerStyle={{borderBottomWidth: 0}}
              errorStyle={{display: "none"}}
              inputStyle={{marginBottom: 0}}/>
            <Text style={styles.title}>{modeData[mode].collectionNoun} Preferences</Text>
            <View style={styles.subtitleBox}>
                <Text style={styles.subtitle}>Sections</Text>
                <TouchableOpacity onPress={addSection}>
                    <Icon name="add-outline" size={30} color="black" />
                </TouchableOpacity>
            </View>
            <View style={styles.sectionBox}>
              <DraxProvider>
                <View>
                  <DraxList style={styles.sectionList}
                    data={sections}
                    renderItemContent={({ item, index }) => {                      
                      return (
                        <Input
                            key={index}
                            style={styles.sectionText} placeholder="Enter section name"
                            containerStyle={styles.sectionText} onChangeText={text => renameSection(index, text)}
                            inputContainerStyle={{borderBottomWidth: 0, margin: 0, padding: 0}}
                            inputStyle={{margin: 0}} errorStyle={{display: "none"}} rightIconContainerStyle={{margin: 0}}
                            rightIcon={
                                <TouchableOpacity onPress={() => deleteSection(index)}>
                                    <Icon
                                        name='close-outline'
                                        size={25}
                                        color='black'
                                    />
                                </TouchableOpacity>
                            } value={item}>
                        </Input>
                      )
                    }}
                    onItemReorder={({ fromIndex, toIndex }) => {
                      const newSections = sections.slice()
                      newSections.splice(toIndex, 0, newSections.splice(fromIndex, 1)[0])
                      setSections(newSections)
                      setListKey(listKey + 1)
                    }}
                    keyExtractor={(item, index) => listKey + index}
                  />
                </View>
              </DraxProvider>
            </View>
            <View style={styles.buttonContainer}>
                <Button
                    title="Save"
                    buttonStyle={styles.saveButton}
                    onPress={updateRestaurantData}
                />
            </View>
          </KeyboardAvoidingView>
        </TouchableWithoutFeedback>
      </View>
    )
  }