import { create } from 'apisauce'
import FormData from 'form-data'
import Upload from 'react-native-background-upload'
import { Platform } from 'react'
import RNBackgroundUpload from 'react-native-background-upload';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import * as FileSystem from 'expo-file-system';
import * as mime from 'mime'
// import { FFmpegKit } from 'ffmpeg-kit-react-native';

// const apiURL = "https://api.crtsn.com"
const apiURL = "https://hellohellohello111.asuscomm.com"
//const apiURL = "http://192.168.2.93"

const api = create({
    baseURL: apiURL,
})

export function getMenu(restaurantId, callback) {
    api.get('/restaurants/' + restaurantId + '/menu')
        .then((response) => {
            callback(response.data)
        })
        .then(console.log)
        .catch(console.log)
};

export function addUser(uid, displayName, email, provider, authCode, callback) {
    let userData = {
        user_id: uid,
        user_name: displayName,
        email: email,
        user_provider_type: provider,
        apple_auth_code: authCode
    }
    api.post('/users/', userData)
        .then(callback)
        .catch(callback)
};

export function deleteUser(uid, callback) {
    api.delete('/users/' + uid)
        .then(callback)
        .catch(callback)
};

export function updateUser(uid, user, callback) {
    api.put('/users/' + uid, user)
        .then(callback)
        .catch(callback)
};

export function getUser(userId, callback) {
    api.get('/users/' + userId)
        .then((response) => {
            callback(response.data)
        })
        .then(console.log)
        .catch(console.log)
};

export function getRestaurant(restaurantId, callback) {
    api.get('/restaurants/' + restaurantId)
        .then((response) => {
            callback(response.data)
        })
        .then(console.log)
        .catch(console.log)
};

export function updateRestaurant(restaurantId, restaurantData, callback) {
    api.put('/restaurants/' + restaurantId, restaurantData)
        .then((response) => {
            callback(response.data)
        })
        .then(console.log)
        .catch(console.log)
};

export function updateMenuItem(restaurantId, section, index, itemData, newSection, newIndex, callback=() => {}) {
    // Double nested params required
    const params = { 
        params: {
            new_section: newSection,
            new_index: newIndex
        }
    }
    console.log(newSection, newIndex)
    api.put('/restaurants/' + restaurantId + '/menu/' + section + '/' + index, itemData, params)
        .then((response) => {
            callback(response.data)
        })
        .catch(console.log)
};

export function deleteMenuItem(restaurantId, section, index, callback=() => {}) {
    api.delete('/restaurants/' + restaurantId + '/menu/' + section + '/' + index)
        .then((response) => {
            callback(response.data)
        })
        .catch(console.log)
};

async function uploadImageBackground(restaurantId, requestId, image, count) {
    const options = {
        url: apiURL + '/model/upload/' + restaurantId + '/' + requestId + '/' + count,
        path: image,
        method: 'POST',
        type: 'multipart',
        field: 'image',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        },
        customUploadId: image
    }

    const resolveUpload = function(uploadId) {
        return new Promise((resolve, reject) => {
            let l1 = RNBackgroundUpload.addListener('error', uploadId, (data) => {
                reject(data);
                cleanup();
            });
            let l2 = RNBackgroundUpload.addListener('cancelled', uploadId, (data) => {
                reject(data);
                cleanup();
            });
            let l3 = RNBackgroundUpload.addListener('completed', uploadId, (data) => {
                resolve(data);
                cleanup();
            });
            new Promise(r => setTimeout(r, 1000 * 60 * 20)).then(() => {
                reject("Upload timed out")
                cleanup()
            })
            let cleanup = () => {
                l1.remove(); l2.remove(); l3.remove();
            }
        });
    }

    return new Promise(async (resolve, reject) => {
        try{
            //await RNBackgroundUpload.beginBackgroundTask(options.customUploadId);
            let upload = await RNBackgroundUpload.startUpload(options);
            let res = await resolveUpload(options.customUploadId)
            console.log(res.responseBody)
            if (String(res.responseBody).toLowerCase().includes("error")) {
                reject("Server Error")
            } else {
                resolve(res)
            }
            //await RNBackgroundUpload.endBackgroundTask(options.customUploadId);
        } catch(err) {
            console.log(err)
            reject(err)
        }
    })
}

async function uploadImage(restaurantId, requestId, image, count) {
    return new Promise(async (resolve, reject) => {
        const url = apiURL + '/model/upload/' + restaurantId + '/' + requestId + '/' + count
    
        try {
            const response = await FileSystem.uploadAsync(url, image, {
                fieldName: 'image',
                httpMethod: 'POST',
                uploadType: FileSystem.FileSystemUploadType.MULTIPART,
            });
            console.log(response.body);
            if (response.body.toLowerCase().includes("error")) {
                reject("Server Error")
            }
            resolve("Success")
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}

export async function createModel(restaurantId, images, background, callback=() => {}, updateCallback=() => {}, errorCallback=() => {}) {
    if (!background) {
        let formData = new FormData()

        for (let image in images) {
            console.log(images[image])
            let file
            if (images[image]?.name) {
                file = images[image]
            } else {
                file = {
                    uri: images[image],
                    name: images[image].split('/').pop(),
                    type: mime.getType(images[image]),
                }
            }
            formData.append('images', file)
        }

        api.post('/model/create_single/' + restaurantId, formData, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: (data) => {
                return data;
            }
        })
        .then((response) => {
            console.log(response)
            if (response.data && response.status < 300 && response.status >= 200) {
                console.log("success callback")
                callback(response.data)
            } else {
                console.log("error callback")
                errorCallback(response.data)
            }
            return response.data
        })
        .then(console.log)
        .catch(console.log)
        return
    }

    let processedImages = []
    for (let image in images) {
        let fileUri = images[image]
        let lowerCaseUri = fileUri.toLowerCase()
        let fileFolder = fileUri.split("/").slice(0, -1).join("/")
        let outputFileFolder = fileFolder + '/video_frames'
        if (lowerCaseUri.includes('.mov') || lowerCaseUri.includes('.mp4') || lowerCaseUri.includes('.ogg') || lowerCaseUri.includes('.avi')) {
            await FileSystem.deleteAsync(outputFileFolder, {idempotent: true})
            await FileSystem.makeDirectoryAsync(outputFileFolder, {intermediates: true})
            await FFmpegKit.execute('-i '+ fileUri + " -r 3/1 " + outputFileFolder + "/image%04d.png")
            let sampledFrames = await FileSystem.readDirectoryAsync(outputFileFolder)
            for (let i in sampledFrames) {
                processedImages.push(outputFileFolder + '/' + sampledFrames[i])
            }
        } else {
            processedImages.push(fileUri)
        }
    }

    processedImages = processedImages.splice(0, 130)
    let uploadPromises = []
    let requestId = uuidv4()
    let count = processedImages.length
    let maxRequests = 5
    let lastRequestImage = processedImages.pop(0)
    let progress = 1

    console.log("Sending upload requests with id " + requestId)

    while (processedImages.length > 0) {
        await Promise.all(processedImages.splice(0, maxRequests).map(async (image) => {
            uploadPromises.push(await uploadImage(restaurantId, requestId, image, count))
            progress += 1
            updateCallback(progress / count)
        }))
    }

    uploadPromises.push(uploadImage(restaurantId, requestId, lastRequestImage, count))

    Promise.all(uploadPromises).then((result) => {
        console.log(result)
        updateCallback()
        callback(result)
    }).catch((err) => {
        console.log(err)
        errorCallback(err)
    });
}    

